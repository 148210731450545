import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Box from "@material-ui/core/Box"
import coffeeIcon from "../../static/assets/buy_coffee_icon.png"
import FacebookIcon from "@material-ui/icons/Facebook"
import TwitterIcon from "@material-ui/icons/Twitter"
import EmailIcon from "@material-ui/icons/Email"
import LinkedInIcon from "@material-ui/icons/LinkedIn"
import { OutboundLink } from "gatsby-plugin-gtag"

export const pageQuery = graphql`
  query AboutQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      excerpt(pruneLength: 150)
      frontmatter {
        title
        author
        authorDescription
        linkedIn
        facebook
        twitter
        buy_me_coffee
        email
        authorImage {
          childImageSharp {
            fluid(maxWidth: 72, maxHeight: 72, quality: 80) {
              ...GatsbyImageSharpFluid
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
    }
  }
`
const SocialIcons = ({ data }) => {
  const { linkedIn, facebook, twitter, buy_me_coffee, email } = data
  return (
    <div className="about-contact-icons">
      <OutboundLink href={linkedIn} target="_blank" rel="noreferrer noopener">
        <LinkedInIcon fontSize="large" htmlColor="#0e76a8" />
      </OutboundLink>
      <OutboundLink href={facebook} target="_blank" rel="noreferrer noopener">
        <FacebookIcon fontSize="large" htmlColor="#3b5998" />
      </OutboundLink>
      <OutboundLink href={twitter} target="_blank" rel="noreferrer noopener">
        <TwitterIcon fontSize="large" htmlColor="#00acee" />
      </OutboundLink>
      <OutboundLink href={buy_me_coffee} target="_blank" rel="noreferrer noopener">
        <img src={coffeeIcon} alt="Buy Me A Coffee" className="coffee-icon" />
      </OutboundLink>
      <OutboundLink href={`mailto:${email}`}>
        <EmailIcon fontSize="large" htmlColor="#D44638" />
      </OutboundLink>
    </div>
  )
}

class AboutPage extends React.Component {
  constructor(props) {
    super(props)
    const { data } = props
    this.state = {
      data,
    }

    if (typeof document !== `undefined`) {
      let script = document.createElement("script")
      script.setAttribute("data-name", "BMC-Widget")
      script.src = "https://cdnjs.buymeacoffee.com/1.0.0/widget.prod.min.js"
      script.setAttribute("data-id", "garlenjavier")
      script.setAttribute("data-description", "Support me on Buy me a coffee!")
      script.setAttribute(
        "data-message",
        "Thank you for visiting Meek Code. Please support me with a coffee!"
      )
      script.setAttribute("data-color", "#FF813F")
      script.setAttribute("data-position", "")
      script.setAttribute("data-x_margin", "18")
      script.setAttribute("data-y-margin", "18")
      script.async = true
      //Call window on load to show the image
      script.onload = function () {
        var evt = document.createEvent("Event")
        evt.initEvent("load", false, false)
        window.dispatchEvent(evt)
      }
      this.script = script
    }
  }

  componentDidMount() {
    if (typeof document !== `undefined`) document.head.appendChild(this.script)
  }

  componentWillUnmount() {
    if (typeof document !== `undefined`) {
      document.head.removeChild(this.script)
      if(document.getElementById("bmc-wbtn"))
        document.body.removeChild(document.getElementById("bmc-wbtn"))
    }
  }

  render() {
    const { data } = this.state
    if (data.markdownRemark == null) return ""

    const { markdownRemark } = data // data.markdownRemark holds your post data
    const { frontmatter, html, excerpt } = markdownRemark
    const Image = frontmatter.authorImage
      ? frontmatter.authorImage.childImageSharp.fluid
      : ""

    return (
      <Layout className="page">
        <SEO title={frontmatter.title + " - " + frontmatter.author} 
        //description={frontmatter.author + " - " + excerpt}
        description={excerpt}
         />
        <div className="wrapper">
          <div className="about-title">
            <h2>{frontmatter.title}</h2>
          </div>

          <Box
            flexDirection="column"
            display="flex"
            alignItems="center"
            className="about-title-box"
          >
            <div className="avatar-about-border">
              {Image ? (
                <Img
                  fluid={Image}
                  objectFit="cover"
                  objectPosition="50% 50%"
                  alt={"author avatar"}
                  className="avatar-about"
                />
              ) : (
                ""
              )}
            </div>
            <div className="author-title-about">
              <div className="name">{frontmatter.author}</div>
              <div className="description">{frontmatter.authorDescription}</div>
            </div>
            <SocialIcons data={frontmatter} />
          </Box>
          <Card elevation={3} className="about-content-box">
            <CardContent>
              <article
                className="about-article"
                dangerouslySetInnerHTML={{ __html: html }}
              />
            </CardContent>
          </Card>
        </div>
      </Layout>
    )
  }
}

export default AboutPage
